import React from "react"
import styled from "styled-components"
import color from "../../style/color"
import { headingFontLight } from "../../style/typography"
import Button from "../../shared/Button/Button"
import { getItem } from "../../util/functions"
import ConfirmationComponent from "./ConfirmationComponent"

const DentistFormConfirmation = () => {
  return (
    <ConfirmationComponent
      category={getItem("category-treatment")}
      denstistName={getItem("dentist-name")}
      firstName={getItem("firstName")}
      lastName={getItem("lastName")}
      practice={getItem("dentist-referral-form-dropdown1")}
      address={getItem("address-1")}
      dob={getItem("dob")}
      phone={getItem("phone")}
      ctaUrl={process.env.BASE}
      page="dentistPage"
      dentistName={getItem("dentist-name")}
      practiceAddress={getItem("p-address-1")}
      practiceName={getItem("practice-name")}
      practicePhone={getItem("practice-number")}
    />
  )
}

export default DentistFormConfirmation
